import { AfterViewInit, Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import * as BABYLON from '@babylonjs/core';
import { TourManager } from '../core/managers/tour';
import { DataService } from '../core/services/data.service';

@Component({
	selector: 'app-viewer',
	templateUrl: './viewer.component.html',
	styleUrls: ['./viewer.component.scss'],
	providers: [DataService]
})
export class ViewerComponent implements AfterViewInit {
	@ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;

	tourManager: TourManager;

	constructor(private renderer: Renderer2, private dataService: DataService) { }

	ngAfterViewInit() {
		this.tourManager = TourManager.getInstance();
		this.tourManager.canvas = this.canvas.nativeElement;
		this.tourManager.engine = new BABYLON.Engine(this.canvas.nativeElement, true, undefined, true);

		this.dataService.fetchData();
		this.dataService.dataRetrieved$.subscribe(() => this.tourManager.createTour(this.dataService.tour));
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		if (this.tourManager.engine != null)
			this.tourManager.engine.resize();
	};

}

import { Component } from '@angular/core';

enum IconType {
  Audio,
  Data,
  Form,
  Image,
  Info,
  Link,
  Mode,
  Video,
  Default
}

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})

export class IconComponent {
  enum: typeof IconType = IconType;
  IconType = IconType.Default;
}

import { IPhoto } from "../interface/IPhoto";
import { ISpace } from "../interface/ISpace";
import { AdvancedDynamicTexture } from "@babylonjs/gui";
import { Widget } from "./widget";
import { Connection } from "./connection";
import { CameraManager } from "../managers/camera";
import { TourManager, TourState } from "../managers/tour";
import * as BABYLON from '@babylonjs/core';

import "@babylonjs/loaders/glTF";

export class Space implements ISpace {
    id: number;
    title: string;
    top: number;
    left: number;
    imageUrl: string;
    textureURL: string;
    videoURL: string;
    hasQuickAccess: boolean;
    connections: Connection[];
    widgets: Widget[];
    oldPhotos: IPhoto[];

    dome: BABYLON.PhotoDome | BABYLON.VideoDome | null;

    cameraManager: CameraManager;
    tourManager: TourManager;

    readonly domeOptions: any;

    constructor(space: ISpace) {
        // this.id = iSpace.id;
        // this.title = iSpace.title;
        // this.top = iSpace.top;
        // this.left = iSpace.left;
        // this.hasQuickAccess = iSpace.hasQuickAccess;
        // this.imageUrl = iSpace.imageUrl;
        // this.connections = iSpace.connections;
        // this.widgets = iSpace.widgets;
        // this.oldPhotos = iSpace.oldPhotos;
        Object.assign(this, space);

        this.connections = [];
        this.widgets = [];

        if (space.connections) {
            for (let connection of space.connections) {
                this.connections.push(new Connection(connection));
            }
        }

        if (space.widgets) {
            for (let widget of space.widgets) {
                this.widgets.push(new Widget(widget));
            }
        }

        this.domeOptions = {
            size: 100,
            useDirectMapping: false
        };

        this.cameraManager = CameraManager.getInstance();
        this.tourManager = TourManager.getInstance();

        this.setupObservables();
    }

    setupObservables() {
        this.tourManager.stateObservable$.subscribe((state) => {
            switch (state) {
                case TourState.Guidance:
                case TourState.Timeline:
                case TourState.ModelCamera:
                    this.setVisibilityOfAllHotspots(false);
                    break;
                case TourState.Default:
                default:
                    this.setVisibilityOfAllHotspots(true);
                    break;
            }
        });
    }

    disposeSpace() {
        this.dome?.dispose(true, true);

        for (let connection of this.connections) {
            connection.dispose();
        }

        for (let widget of this.widgets) {
            widget.dispose();
        }

        this.dome = null;
    }

    createDome() {
        if (this.videoURL) {
            this.createVideoDome();
        } else {
            this.createImageDome();
        }

        this.onLoadObservableAdd(() => this.createHotspots());

        //TODO move this to only be triggered once on tour creation
        this.onLoadObservableAdd(() => this.tourManager.sceneIsRendering());
    }

    createImageDome() {
        this.dome = new BABYLON.PhotoDome(
            "Dome_" + this.id.toString(),
            this.imageUrl,
            this.domeOptions,
            this.tourManager.scene
        );

        this.createNadar(this.imageUrl)
    }

    createVideoDome() {
        this.dome = new BABYLON.VideoDome(
            "Dome_" + this.id,
            this.videoURL,
            this.domeOptions,
            this.tourManager.scene
        );

        //TODO: check user interaction and unmute
        this.dome.videoTexture.video.muted = true; //allows autoplay when user has not interacted with DOM
        this.dome.videoTexture.video.play();
    }

    createHotspots() {
        this.createWidgets();
        this.createConnections();
    }

    createWidgets() {
        for (let widget of this.widgets) {
            widget.createWidgetButton(this.tourManager.scene, true);

            if (widget.focus) {
                this.cameraManager.targetMeshAnimated(widget.mesh);
            }
        }
    }

    createConnections() {
        for (let connection of this.connections) {
            connection.createWidgetButton(this.tourManager.scene, false);

            if (connection.focus) {
                this.cameraManager.targetMeshAnimated(connection.mesh);
            }
        }
    }

    onLoadObservableAdd(callback: any) {
        this.dome?.mesh?.onMeshReadyObservable.add(callback);
    }

    hasLoaded() {
        return this.dome?.mesh.isReady();
    }

    getConnectionTo(title: string) {
        let connections = this.connections.filter(connection => connection.title === title);

        if (connections.length > 0)
            return connections[0];

        return null;
    }

    //TODO - rewrite with data binding on the widgets
    setWidgetSizes(isSmall: boolean) {
        for (var i = 0; i < this.widgets.length; i++) {
            if (isSmall && this.widgets[i].mesh) {
                this.widgets[i].setScaleAnimated(0.6);
            } else if (!isSmall && this.widgets[i].mesh) {
                this.widgets[i].setScaleAnimated(1);
            }
        }
    }

    //TODO - rewrite with data binding on the widgets
    setVisibilityOfAllHotspots(isVisible: boolean) {

        for (const widget of this.widgets) {
            if (widget.mesh)
                widget.mesh.isVisible = isVisible;
        }

        for (const connection of this.connections) {
            connection.mesh.isVisible = isVisible;
        }
    }

    async createNadar(texture: string) {
        let diffuseEmissiveTexture = new BABYLON.Texture(texture);
        diffuseEmissiveTexture = <AdvancedDynamicTexture>diffuseEmissiveTexture;
        diffuseEmissiveTexture.wAng = Math.PI;
        diffuseEmissiveTexture.vAng = Math.PI / 2;

        const material = new BABYLON.StandardMaterial('nadarMaterial');
        material.diffuseTexture = diffuseEmissiveTexture;
        material.emissiveTexture = diffuseEmissiveTexture;
        material.opacityTexture = new BABYLON.Texture("./assets/images/nadar.png");
        material.opacityTexture.coordinatesIndex = 1;

        let imports = await BABYLON.SceneLoader.ImportMeshAsync("", "./assets/3d/", "nadar.glb");
        let plane = imports.meshes[1]
        plane.scaling = new BABYLON.Vector3(2.3, 2.3, 2.3);
        plane.position = new BABYLON.Vector3(0, -5.5, 0)
        //plane.rotation.x = Math.PI / 2
        plane.material = material;
    }

}

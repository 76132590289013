import { IColor } from "../interface/IColor";
import { ITour } from "../interface/ITour";
import { Space } from "./space";

export class Tour implements ITour {
    title: string;
    subtitle: string;
    introVideo: string;
    loadingImage: string;
    logoImage: string;
    logoWebsiteURL: string;
    mapImage: string;
    colors: IColor;
    spaces: Space[];

    // constructor(iTour: ITour) {
    //     this.title = iTour.title;
    //     this.subtitle = iTour.subtitle;
    //     this.loadingImage = iTour.loadingImage;
    //     this.introVideo = iTour.introVideo;
    //     this.logoImage = iTour.logoImage;
    //     this.logoWebsiteURL = iTour.logoWebsiteURL;
    //     this.mapImage = iTour.mapImage;
    //     this.colors = iTour.colors;
    //     this.spaces = iTour.spaces;
    // }

    constructor(tour: ITour) {
        Object.assign(this, tour);

        this.spaces = [];
        for (let space of tour.spaces) {
            this.spaces.push(new Space(space));
        }

    }

}
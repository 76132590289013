import { ArcRotateCamera, BlurPostProcess, Camera, CubeTexture, FreeCamera, Mesh } from '@babylonjs/core';
import * as BABYLON from '@babylonjs/core';
import { TourManager, TourState } from './tour';

export class CameraManager {

  private static instance: CameraManager;

  //TODO implement universal and rotational camera and method to swith them. Stop destroying and recreating.

  camera: Camera;
  baseFov: number;
  blurPostProcess: BlurPostProcess;

  tourManager: TourManager;

  freeCamera: FreeCamera;
  arcCamera: ArcRotateCamera;
  hdrTexture: CubeTexture;

  constructor() {

  }

  static getInstance(): CameraManager {
    if (!CameraManager.instance) {
      CameraManager.instance = new CameraManager();
    }

    return CameraManager.instance;
  }

  initialise() {
    this.tourManager = TourManager.getInstance();
    this.tourManager.stateObservable$.subscribe((state) => this.useCamera(state));

    this.baseFov = 1.1;
    this.freeCamera = this.initialiseFreeCamera();
    this.arcCamera = this.initialiseArcCamera();
    this.blurPostProcess = this.initialiseBlurPostProcess();

    this.hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("https://charliebehan.github.io/DromoneARDemo/environment.dds", this.tourManager.scene);
    this.hdrTexture.gammaSpace = false;

    this.camera = this.freeCamera;
  }

  initialiseFreeCamera() {

    let camera = new BABYLON.FreeCamera("UniversalCamera", new BABYLON.Vector3(0.01, 0, 0), this.tourManager.scene);
    camera.attachControl(this.tourManager.canvas, true);
    camera.fov = this.baseFov;
    camera.ellipsoid = BABYLON.Vector3.One();
    camera.setTarget(new BABYLON.Vector3(5, 0, 0));
    camera.invertRotation = true;
    camera.noRotationConstraint = false;
    camera.inverseRotationSpeed = 1;
    camera.inputs.removeByType("FreeCameraKeyboardMoveInput");
    camera.inputs.remove(camera.inputs.attached['mousewheel']);

    var limitRotX = Math.PI * .28;
    this.tourManager.scene.registerBeforeRender(function () {
      if (camera.rotation.x > limitRotX) {
        camera.rotation.x = limitRotX;
      } else if (camera.rotation.x < -limitRotX) {
        camera.rotation.x = - limitRotX;
      }
    });

    return camera;
  }

  initialiseArcCamera() {
    const LongRot = -6;
    const LatRot = 1.8;
    const RadiusDistance = -3;

    let camera = new BABYLON.ArcRotateCamera("ArcCamera", LongRot, LatRot, RadiusDistance, BABYLON.Vector3.Zero(), this.tourManager.scene);
    camera.attachControl(this.tourManager.canvas, true);
    camera.minZ = 0.01;
    camera.allowUpsideDown = false;
    camera.wheelPrecision = 150;
    camera.pinchDeltaPercentage = 0.0001;
    camera.useNaturalPinchZoom = false;
    camera.pinchPrecision = 0.0001;
    camera.panningSensibility = 0;
    camera.rebuildAnglesAndRadius();
    camera.radius = 3;
    camera.lowerRadiusLimit = 1;
    camera.upperRadiusLimit = 10;

    camera.useAutoRotationBehavior = true;
    if (camera.autoRotationBehavior != null) {
      camera.autoRotationBehavior.idleRotationSpeed = 0.1;
      camera.autoRotationBehavior.idleRotationWaitTime = 10;
      camera.autoRotationBehavior.idleRotationSpinupTime = 10;
      camera.autoRotationBehavior.zoomStopsAnimation = false;
    }

    return camera;
  }

  initialiseBlurPostProcess(): BlurPostProcess {
    const Kernel = 32;
    const Direction = new BABYLON.Vector2(3.0, 0);
    const Options = 1;

    let blurPostProcess = new BABYLON.BlurPostProcess(
      "HorizontalBlur",
      Direction,
      Kernel,
      Options,
      this.camera,
      BABYLON.Texture.BILINEAR_SAMPLINGMODE,
      this.tourManager.engine,
      true
    );

    let animationKeys = [];
    animationKeys.push({ frame: 0, value: BABYLON.Vector2.Zero() });
    animationKeys.push({ frame: 50, value: new BABYLON.Vector2(0, 2.0) });

    let blurAnimation = new BABYLON.Animation("blurAnimation", 'direction', 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR2, BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE);
    blurAnimation.setKeys(animationKeys);
    blurPostProcess.animations = [];
    blurPostProcess.animations.push(blurAnimation);

    return blurPostProcess;
  }

  useCamera(state: TourState) {
    if (state == TourState.ModelCamera) {
      this.camera.detachControl();
      this.tourManager.scene.activeCamera = this.arcCamera;
      this.tourManager.scene.environmentTexture = this.hdrTexture;
    }
    else {
      this.tourManager.scene.activeCamera = this.freeCamera;
      this.camera.attachControl(this.tourManager.canvas, true);
      this.tourManager.scene.environmentTexture = null;
    }
  }

  //TODO - implement close button as a component
  switchToCamera(camera: Camera) {
    this.tourManager.scene.activeCamera = camera;

    //TODO - write as a component
    // const canvasWrap = this.tourManager.canvas;
    // let closeButton = document.createElement("button");
    // closeButton.setAttribute("id", "close3Dbutton");
    // closeButton.style.top = "5%";
    // closeButton.textContent = "close";
    // closeButton.style.height = "30px";
    // closeButton.style.position = "absolute";

    // closeButton.addEventListener("click", () => {
    //   this.tourManager.scene.activeCamera = this.camera;
    //   this.camera.attachControl(this.tourManager.canvas, true);
    //   closeButton.remove();
    //   camera.dispose();
    // });

    // if (canvasWrap != null) {
    //   canvasWrap.appendChild(closeButton);
    // }
  }

  targetMesh(mesh: Mesh) {
    let newTarget = mesh.absolutePosition;
    (<FreeCamera>this.camera).target = newTarget;
  }

  async targetMeshAnimated(mesh: Mesh) {

    this.camera.detachControl(this.tourManager.canvas);

    let currentTarget = (<FreeCamera>this.camera).target;
    let newTarget = mesh.absolutePosition
    let animation = BABYLON.Animation.CreateAndStartAnimation(
      'targetAnimation',
      this.camera,
      'target',
      60,
      40,
      currentTarget,
      newTarget,
      undefined,
      new BABYLON.SineEase()
    );

    await animation?.waitAsync();

    this.camera.attachControl(this.tourManager.canvas, true);
  }

  transitionOutEffect() {

    const FramesPerSecond = 120;
    const FramesTotal = 30;
    const TargetFov = 1.5;
    BABYLON.Animation.CreateAndStartAnimation("fieldOfViewAnimation", this.camera, "fov", FramesPerSecond, FramesTotal, this.baseFov, TargetFov, undefined);

    this.tourManager.scene.beginAnimation(this.blurPostProcess, 0, 50, false, 3);
  }

  transitionInEffect() {

    const FramesPerSecond = 120;
    const FramesTotal = 30;
    const TargetFov = 1.5;
    BABYLON.Animation.CreateAndStartAnimation("fieldOfViewAnimation", this.camera, "fov", FramesPerSecond, FramesTotal, TargetFov, this.baseFov, undefined);

    this.tourManager.scene.beginAnimation(this.blurPostProcess, 50, 0, false, 3);

    //TODO - implement in space service, irrellevant to camera service
    //this.appManager.spaceManager.isLoadingSpace = false;
  }
}

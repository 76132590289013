import { IWidget } from "../interface/IWidget";
import { IWidgetContent } from "../interface/IWidget-Content";
import { Hotspot } from "./hotspot";
import { Scene } from "@babylonjs/core";
import * as BABYLON from '@babylonjs/core';
import * as GUI from '@babylonjs/gui';
import { TourManager, TourState } from "../managers/tour";



enum WidgetType {
    Audio,
    Data,
    Form,
    Image,
    Info,
    Link,
    Model,
    Video,
    Custom,
    Default
}

export class Widget extends Hotspot implements IWidget {

    WidgetEnum: typeof WidgetType = WidgetType;
    WidgetType = WidgetType.Default;

    focusOnThisWidget: boolean;
    x: number;
    y: number;
    widgetContent!: IWidgetContent;

    constructor(widget: IWidget) {
        super("widget", widget.x, widget.y, widget.focusOnThisWidget);

        //Object.assign(widget, this);
        this.x = widget.x;
        this.y = widget.y;

        //TODO move to widget content class?
        // this.onHoverText = parsedJSONWidget.widgetContent.onHoverText;
        // this.title = parsedJSONWidget.widgetContent.title || null;
        // this.type = null;
        // this.content = parsedJSONWidget.widgetContent.content;
        // if (this.content.length == 1) {
        //     this.type = this.content[0]._type;
        // }
    }

    override createWidgetButton(scene: Scene, showText: boolean): void {
        this.text = this.widgetContent.onHoverText;

        //TODO get correct type
        this.WidgetType = this.widgetContent.content.length == 1
            //? this.widgetContent.content[0]._type
            ? WidgetType.Info
            : WidgetType.Default;

        super.createWidgetButton(scene, showText);
        this.createClickEvent();
    }


    getIconTexture(widgetType: WidgetType) {
        switch (widgetType) {
            case WidgetType.Audio:
                return "./assets/icons/audio.svg";
            case WidgetType.Data:
                return "./assets/icons/data.svg";
            case WidgetType.Form:
                return "./assets/icons/form.svg";
            case WidgetType.Image:
                return "./assets/icons/image.svg";
            case WidgetType.Info:
                return "./assets/icons/info.svg";
            case WidgetType.Link:
                return "./assets/icons/link.svg";
            case WidgetType.Model:
                return "./assets/icons/model.svg";
            case WidgetType.Video:
                return "./assets/icons/video.svg";
            default:
                return "./assets/icons/info.svg";
        }
    }

    override createIcon() {
        //var icon = new GUI.Image("icon", this.getIconTexture(this.widgetContent.content[0]._type));
        var icon = new GUI.Image("icon", this.getIconTexture(WidgetType.Info));
        icon.width = "240px";
        icon.height = "240px";
        return icon;
    }

    override setHoverEvents() {
        super.setHoverEvents();

        this.button.pointerEnterAnimation = () => this.animateTextVisible(true);
        this.button.pointerOutAnimation = () => this.animateTextVisible(false);
    }

    createClickEvent() {
        if (this.WidgetType === WidgetType.Custom)
            return;

        switch (this.WidgetType) {
            case WidgetType.Model:
                this.button.onPointerClickObservable.add(() => this.focusOn3DModel());
                break;

            default:
                // this.button.onPointerClickObservable.add(() => new PanelGroup(this.title, this.widgetGroup, this.content, this.type, this.appManager, false));
                break;
        }
    }










    //TODO - Rewrite 3D model section to relevant managers
    // Add Tour service
    // move 3dmodelrendered to tour state type parameter
    // 

    async focusOn3DModel() {

        let tourManager = TourManager.getInstance();
        tourManager.setFocus(TourState.ModelCamera);

        // let model = await BABYLON.SceneLoader.ImportMeshAsync("3D_Model", this.content[0].modelURL);
        // model.meshes[0].scaling = new BABYLON.Vector3(2, 2, 2);
        // this.switchCamera(model);
    }

    // switchCamera(model) {
    //     let onClose = () => {
    //         this.appManager.scene.removeMesh(model);
    //         model.meshes[0].dispose();
    //         this.appManager.is3DModelRendered = false;
    //     };
    // }
}

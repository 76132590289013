import { IConnection } from '../interface/IConnection';
import { Hotspot } from './hotspot';
import * as BABYLON from '@babylonjs/core';


//TODO - revise connection widget implementation.
export class Connection extends Hotspot implements IConnection {

    //TODO - use space id instead of title, rename variables
    title: string;
    displayText: string;
    focusOnThisConnection: boolean;
    x: number;
    y: number;
    arrowDirection: string;

    constructor(connection: IConnection) {

        super(connection.displayText, connection.x, connection.y, connection.focusOnThisConnection);
        //Object.assign(connection, this);
        // this.rotationX = rotationX;
        // this.rotationY = rotationY;

        this.displayText = this.displayText ?? ("Go to " + this.title);

        this.setClickEvent();
    }


    setClickEvent() {
        //this.button.onPointerClickObservable.add(() => this.appManager.spaceManager.updateSpaceByName(this.title));
    }
}

import { AssetsManager, Engine, PhotoDome, Scene, Texture, VideoDome } from '@babylonjs/core';
import { Subject } from 'rxjs';
import { Tour } from '../models/tour';
import * as BABYLON from '@babylonjs/core';
import { CameraManager } from './camera';
import { SpaceManager } from './space';

// const arrowPath = require("../../../assets/images/icons/arrow.svg") as string;

export enum TourState {
  Default,
  ModelCamera,
  Guidance,
  Timeline
}

export class TourManager {

  private static instance: TourManager;

  scene: Scene;
  canvas: HTMLCanvasElement;
  engine: Engine;
  assetsManager: AssetsManager;

  tour: Tour;

  constructor() { console.log('Tour Manager Created'); }

  static getInstance(): TourManager {
    if (!TourManager.instance) {
      TourManager.instance = new TourManager();
    }
    return TourManager.instance;
  }

  TourState: typeof TourState = TourState;
  private state = new Subject<TourState>();
  stateObservable$ = this.state.asObservable();
  setFocus(state: TourState) {
    this.state.next(state);
  }

  private sceneRendering = new Subject<any>();
  sceneRendering$ = this.sceneRendering.asObservable();
  sceneIsRendering = () => this.sceneRendering.next(null);

  createTour(tour: Tour) {
    this.tour = tour;
    this.createScene();
  }

  createScene() {
    this.scene = new BABYLON.Scene(this.engine);
    this.scene.clearColor = new BABYLON.Color4(0.5, 0.5, 0.5, 1);

    this.assetsManager = new BABYLON.AssetsManager(this.scene);
    this.addIconsTest();

    let cameraManager = CameraManager.getInstance();
    cameraManager.initialise();
    let spaceManager = SpaceManager.getInstance();

    let scene = this.scene;
    this.engine.runRenderLoop(function () {
      scene.render();
    });
  }

  addIconsTest() {
    this.assetsManager.addImageTask("audio icon task", "./assets/icons/audio.svg");
    this.assetsManager.addImageTask("data icon task", "./assets/icons/data.svg");
    this.assetsManager.addImageTask("form icon task", "./assets/icons/form.svg");
    this.assetsManager.addImageTask("image icon task", "./assets/icons/image.svg");
    this.assetsManager.addImageTask("info icon task", "./assets/icons/info.svg");
    this.assetsManager.addImageTask("link icon task", "./assets/icons/link.svg");
    this.assetsManager.addImageTask("model icon task", "./assets/icons/model.svg");
    const vidTask = this.assetsManager.addImageTask("video icon task", "./assets/icons/video.svg");
    vidTask.onSuccess = (task) => {
      console.log(task.image.width);
    };

    // console.log(arrow);

    const tex = new Texture('assets/icons/model.svg', this.scene);
    console.log(tex.name);
  }

  clearScene(dome: PhotoDome | VideoDome | null) {
    this.scene.meshes = [];
    this.scene.materials = [];
    this.scene.cleanCachedTextureBuffer();
    this.scene.clearCachedVertexData();

    if (dome != null) {
      this.scene.meshes.push(dome.mesh);
    }
  }

}

<div *ngIf="isLoading" id="loading-screen" class="loader-background">
  <div class="loader">

    <fa-icon [icon]="videoIcon" class="video"></fa-icon>
    <fa-icon [icon]="imageIcon" class="image"></fa-icon>
    <fa-icon [icon]="volumeIcon" class="volume"></fa-icon>
    <fa-icon [icon]="linkIcon" class="link"></fa-icon>

    <div class="shake">
      <img class="logo" src="assets/images/imr-logo.png" />
    </div>

    <span class="info-text">{{infoText}}</span>
  </div>
</div>
import { OnInit, Component } from '@angular/core';
import { faVideoCamera, faImage, faVolumeUp, faLink } from '@fortawesome/free-solid-svg-icons';
import { TourManager } from '../core/managers/tour';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {
  videoIcon = faVideoCamera;
  imageIcon = faImage;
  volumeIcon = faVolumeUp;
  linkIcon = faLink;

  infoText = "Loading Tour...";

  isLoading: boolean;
  tourManager: TourManager;

  constructor() {
    this.isLoading = true;
    this.tourManager = TourManager.getInstance();
  }

  ngOnInit() {
    this.tourManager.sceneRendering$.subscribe(() => this.setIsLoading(false));
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}

import QueryString from 'query-string';
import { CameraManager } from './camera';
import { TourManager } from './tour';
import { Space } from '../models/space';
import { Hotspot } from '../models/hotspot';

export class SpaceManager {

  private static instance: SpaceManager;

  currentSpace: Space;
  previousSpace: Space;
  isLoadingSpace: boolean;

  tourManager: TourManager;
  cameraManager: CameraManager;

  constructor() {
    this.isLoadingSpace = false;

    this.tourManager = TourManager.getInstance();
    this.cameraManager = CameraManager.getInstance();
    this.currentSpace = this.getStartingSpace();
    console.log(this.currentSpace);
    this.currentSpace.createDome();
  }

  static getInstance(): SpaceManager {
    if (!SpaceManager.instance) {
      SpaceManager.instance = new SpaceManager();
    }

    return SpaceManager.instance;
  }

  getStartingSpace(): Space {
    const id = this.getFromQueryString('id');

    if (!id)
      return this.tourManager.tour.spaces[0];

    return this.findSpaceByID(id) ?? this.tourManager.tour.spaces[0];
  }

  getFromQueryString(key: string) {
    const query = QueryString.parse(location.search);
    let value = query[key]?.toString();
    return value == null ? value : Number.parseInt(value);
  }

  getQuickAccessSpaces() {
    return this.tourManager.tour.spaces.filter(space => space.hasQuickAccess);
  }

  findSpaceByTitle(title: string) {
    title = title.toUpperCase();
    let space = this.tourManager.tour.spaces.find(space => space.title.toUpperCase() == title);
    return space;
  }

  findSpaceByID(id: number) {
    let space = this.tourManager.tour.spaces.find(space => space.id == id);
    return space;
  }

  //TODO - standardise name vs title
  updateSpaceByName(name: string) {
    let space = this.findSpaceByTitle(name);

    if (space == undefined) {
      console.log('space not found! name:' + name);
    }

    this.updateSpace(<Space>space);
  }

  updateSpaceByID(id: number) {
    let space = this.findSpaceByID(id);

    if (space == undefined) {
      console.log('space not found! name:' + name);
    }

    this.updateSpace(<Space>space);
  }


  updateSpace(space: Space) {
    if (!this.isLoadingSpace && this.currentSpace != space && space) {

      this.isLoadingSpace = true;

      this.tourManager.clearScene(this.currentSpace.dome);
      this.previousSpace = this.currentSpace;
      this.currentSpace = space;
      this.createHistoryLog();

      this.cameraManager.transitionOutEffect();
      space.createDome();
      space.onLoadObservableAdd(() => {
        this.cameraManager.transitionInEffect();
        this.previousSpace?.disposeSpace();
      });
    }
  }

  createHistoryLog() {
    const query = QueryString.parse(location.search);
    query['id'] = this.currentSpace.id.toString() ?? null;

    const url = window.location.pathname + '?' + QueryString.stringify(query);
    window.history.pushState({ path: url }, '', url);
  }

  lookAtHotspot(hotspot: Hotspot) {

    if (this.currentSpace.dome?.texture.isReady()) {
      this.cameraManager.targetMeshAnimated(hotspot.mesh);
    }
    else {
      this.currentSpace.dome?.texture.onLoadObservable.add(() => {
        this.cameraManager.targetMeshAnimated(hotspot.mesh);
      });
    }
  }

  //TODO - Check if needed
  hasCurrentSpaceLoaded() {
    return this.currentSpace.hasLoaded();
  }
}

import { Mesh, Scene } from '@babylonjs/core';
import { Button, TextBlock } from '@babylonjs/gui';
import * as BABYLON from '@babylonjs/core';
import * as GUI from '@babylonjs/gui';
import { TourManager } from '../managers/tour';

const TEXT_BLOCK_FRAMES = 20;

export class Hotspot {

    button!: Button;
    mesh!: Mesh;
    textBlock!: TextBlock;

    text: string;
    rotationX: number;
    rotationY: number;
    focus: boolean;

    readonly colorFadedBlack = 'hsla(0, 0 %, 0 %, 0.4)';

    constructor(text: string, rotationX: number, rotationY: number, focus: boolean) {
        this.text = text;
        this.rotationX = rotationX;
        this.rotationY = rotationY;
        this.focus = focus;
    }

    createWidgetButton(scene: Scene, showText: boolean) {
        this.button = this.createButton(showText);
        this.mesh = BABYLON.MeshBuilder.CreatePlane("connectionHotspotMesh", { width: 1.2, height: 1 }, scene);

        // let buttonADT = GUI.AdvancedDynamicTexture.CreateForMesh(this.widgetButtonMesh);
        // buttonADT.addControl(this.widgetButton);

        this.setLocation();
        this.setHoverEvents();
    }

    createButton(showText: boolean) {
        let button = GUI.Button.CreateSimpleButton("hotspotButton", "");
        button.width = "1000px";
        button.height = "1000px";
        button.paddingBottomInPixels = 270;
        button.paddingTopInPixels = 250;
        button.paddingLeftInPixels = 250;
        button.paddingRightInPixels = 250;
        button.color = "transparent"
        button.clipChildren = false;
        button.clipContent = false;

        // var ellipse1 = new GUI.Ellipse();
        // ellipse1.width = "350px";
        // ellipse1.height = "350px";
        // ellipse1.thickness = 0;
        // ellipse1.background = this.appManager.uiElements.colours.colourFaded50;

        let ellipse = new GUI.Ellipse();
        ellipse.width = "500px";
        ellipse.height = "500px";
        ellipse.thickness = 22;
        ellipse.background = this.colorFadedBlack;
        button.addControl(ellipse);

        if (showText && !this.text) {
            this.textBlock = this.createTextBlock();
            button.addControl(this.textBlock)
        }

        button.addControl(this.createIcon());
        button.pointerDownAnimation = () => null;
        button.pointerUpAnimation = () => null;

        return button;
    }

    createTextBlock(): TextBlock {
        let text: TextBlock = new GUI.TextBlock("textBlock", this.text);
        text.textWrapping = GUI.TextWrapping.WordWrap;
        text.paddingTopInPixels = 730;
        text.fontSizeInPixels = 140;
        text.widthInPixels = 1000;
        text.clipContent = false;
        text.resizeToFit = true;
        text.isVisible = true;
        text.shadowBlur = 35;
        text.color = "White";
        text.scaleX = 0.8;
        text.alpha = 0;

        //TODO: revise gui animations
        const Start = 0;
        const Mid = TEXT_BLOCK_FRAMES / 2;
        const End = TEXT_BLOCK_FRAMES;

        text.animations = [];
        let alphaAnimation = new BABYLON.Animation("alphaAnimation", "alpha", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, 0);
        let alphaAnimationKeys = [
            { frame: Start, value: 0 },
            { frame: Mid, value: 1 },
            { frame: End, value: 0 }
        ];
        alphaAnimation.setKeys(alphaAnimationKeys);
        text.animations.push(alphaAnimation);

        let positionAnimation = new BABYLON.Animation("positionAnimation", "paddingTopInPixels", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, 0);
        let animationShowPositionKeys = [
            { frame: Start, value: 600 },
            { frame: Mid, value: 730 },
            { frame: End, value: 600 }
        ];
        positionAnimation.setKeys(animationShowPositionKeys);
        text.animations.push(positionAnimation);

        return text;
    }

    createIcon() {
        let image = new GUI.Image("arrowIcon", "./assets/icons/arrow.svg");
        image.width = "400px";
        image.height = "400px";
        return image
    }

    setLocation() {

        let location = new BABYLON.TransformNode("hotspotLocation");
        location.position = BABYLON.Vector3.Zero();
        location.rotation.x += Math.PI * (this.rotationX * 0.1);
        location.rotation.y += Math.PI * (this.rotationY * 0.1);
        this.mesh.parent = location;

        this.mesh.position.z = 8;
    }

    setHoverEvents() {
        this.button.pointerEnterAnimation = () => this.setScaleAnimated(1.5);
        this.button.pointerOutAnimation = () => this.setScaleAnimated(1);
    }

    setScaleAnimated(scale: number) {
        let ease = new BABYLON.CubicEase();
        ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);

        BABYLON.Animation.CreateAndStartAnimation('HotspotScaleX', this.mesh, 'scaling.x', 60, 10, this.mesh.scaling.x, scale, 0, ease);
        BABYLON.Animation.CreateAndStartAnimation('HotspotScaleY', this.mesh, 'scaling.y', 60, 10, this.mesh.scaling.y, scale, 0, ease);
    }

    //TODO - convert to enum, use switch and binding
    setScale(value: string) {
        if (value == "moving")
            this.mesh.scaling = new BABYLON.Vector3(0.7, 0.7, 0.7);
        else if (value == "hover" && this.mesh.scaling.x != 0.7)
            this.mesh.scaling = new BABYLON.Vector3(1.5, 1.5, 1.5);
        else if (value == "unhover" && this.mesh.scaling.x != 0.7)
            this.mesh.scaling = BABYLON.Vector3.One();
        else if (value != "moving" && value != "hover" && value != "unhover")
            this.mesh.scaling = BABYLON.Vector3.One();
    }


    animateTextVisible(isVisible: boolean) {
        if (!this.textBlock) {
            return;
        }

        //TODO revise how hotspot accesses scene object
        let tourManager = TourManager.getInstance();

        const Start = 0;
        const Mid = TEXT_BLOCK_FRAMES / 2;
        const End = TEXT_BLOCK_FRAMES;

        if (isVisible) {
            tourManager.scene.beginAnimation(this.textBlock, Start, Mid, false);
        }
        else {
            tourManager.scene.beginAnimation(this.textBlock, Mid, End, false);
        }
    }

    setTitleTextVisibility(isVisible: boolean) {
        if (this.textBlock)
            this.textBlock.isVisible = isVisible;
    }

    dispose() {
        this.button.onPointerClickObservable.clear();
        this.button.dispose();
        this.mesh.parent?.dispose();
        this.mesh.dispose(true, true);
    }
}
